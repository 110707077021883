import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.unpkg.com/@wormhole-foundation/wormhole-connect@0.0.1-beta.7/dist/main.js";
    script.async = true;

    const link = document.createElement("link");
    link.href =
      "https://www.unpkg.com/@wormhole-foundation/wormhole-connect@0.0.1-beta.7/dist/main.css";

    document.body.appendChild(script);
    document.body.appendChild(link);
  }, []);

  const config = {
    env: "testnet",
    mode: "dark",
  };

  return <div id="wormhole-connect" config={JSON.stringify(config)}></div>;
}

export default App;
